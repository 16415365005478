import React from "react"
import { Box, Flex, Grid, Heading, Text } from "@theme-ui/components"
import { getArticlePath } from "../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { InboundLink } from "./link"

const ArticleThumb = ({ article, small }) => {
  return (
    <Box
      sx={{
        overflow: "hidden",
        height: "100%",
      }}
    >
      <InboundLink
        to={getArticlePath(article, article.locale)}
        sx={{ textDecoration: "none" }}
      >
        <Grid
          gap={[1]}
        >
          {article.heroImage && (
            <Box
              sx={{
                ".gatsby-image-wrapper": {
                  height: "100%",
                },
              }}
            >
              <GatsbyImage image={article.heroImage.gatsbyImageData} alt="" />
            </Box>
          )}

          <Box>
            <Text
              sx={{
                fontSize: [0],
                letterSpacing: "0.09",
                lineHeight: "1",
                color:"dark",
                fontWeight: "500",
              }}
            >
              {article.meta.firstPublishedAt}
            </Text>
            <Heading
              as="h2"
              variant="h3"
              sx={{
                my: [2],
                color: "dark",
                display: "-webkit-box",
                "-webkit-line-clamp": ["5"],
                "-webkit-boxOrient": "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {article.title}
            </Heading>
          </Box>
        </Grid>
      </InboundLink>
    </Box>
  )
}

export default ArticleThumb
