import React from "react"
import { Box, Grid } from "@theme-ui/components"
import ArticleThumb from "./articleThumb"

const ArticlesList = ({ articles }) => {
  return (
    <Box
      sx={{
        "& > div": {
          mb: [4, 5],
        },
      }}
    >
      <Grid columns={[1, 2, 3]} gap={32}>
        {articles.map(article => (
          <ArticleThumb article={article} key={article.id} />
        ))}
      </Grid>
    </Box>
  )
}

export default ArticlesList
