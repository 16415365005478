import React from "react"
import { graphql } from "gatsby"
import { Container, Box, Heading } from "@theme-ui/components"
import Layout from "../components/layout"
import { getBlogPath } from "../utils/path"
import ArticlesList from "../components/articlesList"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import Breadcrumbs from "../components/breadcrumbs"
import BannerList from "../components/blocks/bannerList"

const Blog = ({ data: { page, articles } }) => {
  // console.log(page)
  const favicon = useFavicon().site.faviconMetaTags

  const i18nPaths = page._allSlugLocales.map(path => {
    return {
      locale: path.locale,
      value: getBlogPath(page, path.locale),
    }
  })

  return (
    <Layout pageSlug={page.slug} locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <Container>
        <Box sx={{ py: [4, 4, 6], pt: [10, 10, 6] }}>
          <Breadcrumbs page={page} />
        </Box>
        <Box>
          <Heading
            as="h1"
            variant="h2"
            sx={{
              fontWeight: "400",
              fontSize: [6, 6, 8],
              lineHeight: "1",
              color: "primary",
              mb: [3, 7],
            }}
          >
            {page.title}
          </Heading>
        </Box>
        <Box>
          {page.bannersCategory && page.bannersCategory.length > 0 && (
            <BannerList banners={page.bannersCategory} channel={page.slug} />
          )}
        </Box>
        <ArticlesList articles={articles.nodes} />
      </Container>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query BlogQuery($id: String!, $locale: String!, $channel: String!) {
    page: datoCmsBlogPage(id: { eq: $id }) {
      ...BlogDetails
      bannersCategory {
        url
        title
        image {
          gatsbyImageData(width: 500, placeholder: BLURRED)
          url
          alt
          title
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    articles: allDatoCmsArticle(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: {
        slug: { ne: null }
        locale: { eq: $locale }
        channel: { slug: { eq: $channel } }
      }
    ) {
      nodes {
        ...ArticleDetails
        ...ArticleAllSlugLocales
        ...ArticleMeta
      }
    }
    articleCategories: allDatoCmsArticleCategory(
      sort: { fields: position, order: ASC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
    ) {
      nodes {
        ...ArticleCategoryDetails
        ...ArticleCategoryAllSlugLocales
      }
    }
    site: datoCmsSite {
      locales
    }
  }

  fragment BlogDetails on DatoCmsBlogPage {
    id
    locale
    slug
    title
    _allSlugLocales {
      value
      locale
    }
    model {
      apiKey
    }
  }
`
